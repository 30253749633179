import actions from './actions.json';
import authorisation from './authorisation.json';
import client from './client.json';
import home from './home.json';
import global from './global.json';
import locale from './locale.json';
import product from './product.json';
import productCategory from './productCategory.json';
import user from './user.json';
import validation from './validation.json';
import errors from './errors.json';
import store from './store.json';
import address from './address.json';
import shoppingCart from './shoppingCart.json';
import catalog from './catalog.json';
import orders from './orders.json';
import hints from './hints.json';
import countries from './countries.json';
import configurator from './configurator.json';
import approvals from './approvals.json';

export default {
  actions,
  authorisation,
  client,
  home,
  countries,
  global,
  locale,
  product,
  productCategory,
  user,
  validation,
  errors,
  store,
  address,
  shoppingCart,
  catalog,
  orders,
  hints,
  configurator,
  approvals,
};
